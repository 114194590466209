.logo a {
    /* width: 90px; */
    width: 100px;
}

.searchIcons {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.searchSec {
    background-color: #fff;
    padding: 3px 0;
    position: relative;
}

.searchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Bar Start */
.searchItem .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bar svg {
    font-size: 29px;
    color: #464646;
    cursor: pointer;
    display: none;
}

/* search Info */
.searchInf {
    background-color: #ffffff;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #ff1e00;
    border-radius: 8px;
}

.searchInf input {
    background-color: #ffffff;
    padding: 0 15px;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    border: 0;
}

.searchInf input:focus {
    box-shadow: none;
    border: 0;
    background-color: #ffffff;
}

.rightSearchInfo {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding-right: 15px;
}

.allCategory {
    border-right: 1px solid #ced4da;
    padding-right: 10px;
}

.allCategory select {
    background-color: transparent;
    border: 0;
    padding: 12px 8px;
    font-size: 14px;
    color: #919191;
}

.allCategory select:focus {
    outline: 0;
}

.searchIcon svg {
    font-size: 22px;
    color: #919191;
    cursor: pointer;
}

/* Call Details */
.callDetail {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.callDetail svg {
    font-size: 20px;
}

.callText h6 {
    margin: 0;
    text-transform: uppercase;
    color: gray;
    font-size: 12px;
}

.callText p {
    color: #111;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
}

.searchIconsItem ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0;
}

.searchIconsItem ul li a {
    font-size: 25px;
    color: rgb(26, 26, 26);
    line-height: 20px;
}

.countParent a {
    position: relative;
}

.count {
    display: flex;
    background-color: #fb641b;
    color: #fff;
    font-size: 13px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 600;
    position: absolute;
    top: -10px;
    right: -10px;
}


/* Trending */
.treandingSec {
    box-shadow: 0 0 8px 0 rgb(7 7 7 / 10%);
    background: #fff;
    position: absolute;
    width: 100%;
    top: 60px;
    transition: .4s ease-in-out;
    z-index: 1;
}

/* .searchSec .searchInf input:hover .treandingSec{
    opacity: 1;
    top: 60px;
    z-index: 1;
} */
.trendingItem {
    border: 1px solid #e8e7e67d;
    border-top: none;
    padding: 16px 15px;
}

.trendingTitle {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.38;
    color: #565656;
}

.trendingItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.trendingItem ul li a {
    padding: 9px 8px;
    border: 1px solid #e8e7e6;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    color: #7c7c7c;
    transition: .4s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
}

.trendingItem ul li a:hover {
    color: #fff;
    border: solid 1px #ff1e00;
    background-color: #ff1e00;
}

.closeBtnDrop {
    font-size: 25px;
    position: absolute;
    top: -98px;
    right: -1px;
}

.trendingItem {
    position: relative;
}